import { render, staticRenderFns } from "./SelectGoodsModal.vue?vue&type=template&id=a678b73c&scoped=true"
import script from "./SelectGoodsModal.vue?vue&type=script&lang=js"
export * from "./SelectGoodsModal.vue?vue&type=script&lang=js"
import style0 from "./SelectGoodsModal.vue?vue&type=style&index=0&id=a678b73c&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a678b73c",
  null
  
)

export default component.exports