<template>
  <a-modal
      v-drag-modal
      title="选择商品"
      :visible="visible"
      :maskClosable="false"
      :width="1200"
      :height="700"
      @ok="handleOk"
      @cancel="visible = false"
  >
    <div style="padding:10px;">
      <div class="search">
        <!-- 搜索区域 -->
        <div class="items" style="flex: none">
          <div class="one-item" style="width: 200px">
            <a-input
                class="input"
                v-model="queryParams.goodsTitle"
                placeholder="商品名称"
                allowClear
            />
          </div>
        </div>
        <div class="operator">
          <a-button type="primary" @click="search(1)">查询</a-button>
          <a-button style="margin-left: 8px" @click="reset">重置</a-button>
        </div>
      </div>
    </div>
    <a-table ref="TableInfo" size="small" :bordered="true"
             :columns="columns"
             rowKey="id"
             :dataSource="dataSource"
             :pagination="pagination"
             @change="handleTableChange"
             :loading="loading"
             :scroll="{y:400,x:600}">
      <template slot="masterImage" slot-scope="text, record">
        <div v-if="record.masterImage" class="img-list">
          <el-image
              style="width: 40px; height: 40px"
              :src="(record.masterImage || '') "
              fit="contain"
              :preview-src-list="[record.masterImage]"
          />
        </div>
      </template>
      <template slot="platform" slot-scope="text, record">
        <a-tag :color="record.platform | dictName('goodsPlatform', 'color')">{{
            record.platform | dictName("goodsPlatform")
          }}
        </a-tag>
      </template>
      <template slot="status" slot-scope="text, record">
        <a-tag :color="record.status | dictName('validStatus', 'color')">{{
            record.status | dictName("validStatus")
          }}
        </a-tag>
      </template>
      <template slot="operation" slot-scope="text, record">
        <a-button type="primary" size="small" style="margin-right:15px;"  @click="selectItem(record)">选择
        </a-button>
      </template>
    </a-table>
  </a-modal>
</template>

<script>
import {ListMixin} from "@/mixin/list-mixin";

export default {
  name: "SelectGoodsModal",
  props: {},
  mixins: [ListMixin],
  data() {
    return {
      listGetUrl: "goods/goodsList",
      visible: false,
      selected: undefined
    }
  },
  computed: {
    columns() {
      let {sortedInfo, filteredInfo} = this;
      sortedInfo = sortedInfo || {};
      filteredInfo = filteredInfo || {};
      return [
        {
          title: "ID",
          width: 80,
          align: "center",
          dataIndex: "id",
        },
        {
          title: "Logo",
          width: 80,
          align: "center",
          dataIndex: "masterImage",
          scopedSlots: {customRender: "masterImage"},
        },
        {
          title: "分类",
          align: "center",
          width: 100,
          dataIndex: "cateName",
        },
        {
          title: "商品名称",
          align: "center",
          width: 300,
          dataIndex: "goodsTitle",
          scopedSlots: {customRender: "goodsTitle"},
        },

        {
          title: "状态",
          align: "center",
          width: 80,
          dataIndex: "status",
          scopedSlots: {customRender: "status"},
        },

        {
          title: "商品来源",
          align: "center",
          width: 80,
          dataIndex: "platform",
          scopedSlots: {customRender: "platform"},
        },
        {
          title: "商品价格",
          align: "center",
          width: 100,
          dataIndex: "price",
          scopedSlots: {customRender: "price"},
        },
        {
          title: '操作',
          width: 100,
          align: 'center',
          fixed: 'right',
          dataIndex: 'operation',
          scopedSlots: {customRender: 'operation'}
        }
      ];
    },
  },
  mounted() {
    this.search(1);
  },
  methods: {
    showModal() {
      this.selected = []
      this.visible = true
    },
    handleOk() {
      this.visible = false;
    },

    selectItem(item) {
      this.visible = false;
      this.$emit("onSelected",item);
    }
  }

}
</script>

<style scoped lang="less">

.step_box {
  margin: 0px auto;
  border: 1px #E5E5E5 solid;


  .step_box_head {
    border-bottom: 1px #E5E5E5 solid;
    color: #666;
    font-size: 16px;
    font-weight: 100;
    padding: 15px 40px 15px 0;
    background: #F5F5F5;
  }

  .step_menu {
    ul li {
      float: left;
      margin-right: 20px;
      border: 0 none;
      list-style: none;
      cursor: pointer;
    }

    ul li span {
      display: block;
      background: url(/static/images/step_menu.png) left bottom no-repeat;
      font-size: 14px;
      width: 146px;
      height: 35px;
      line-height: 35px;
      padding-left: 30px;
    }

    ul li.checked span {
      background: url(/static/images/step_menu.png) left top no-repeat;
      color: #FFF;
      border: 0 none;
    }
  }
}

/deep/ .ant-modal-body {
  padding: 0 0px;
}
</style>
